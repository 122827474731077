.card-login{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

body {
  background: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.cardTitle{
  color:white;
  font-size: 15px;
  text-decoration: none;
}

.cardTitle:hover{
  font-weight: 600;
  color:white;
}

.index{
  padding-left: 10%;
  margin-right: 10%;
  margin-bottom: 15%;
}


