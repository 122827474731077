a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}


#navbarNav{
  display: flex;
  justify-content: flex-end; 
  width: 100%;
}

.navbarTextos{
  padding-top: 0.5%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 0.5%;
}

.nav-item {
  margin-left: 15px; 
  margin-right: 15px;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.fondo{
  background-color: #00234b;
  color: white;
}

.navbar a {
  color: white !important;
}

.navbar a:hover{
  color: #fed102 !important;
}

.m-15{
  margin-bottom: 90px;
}