/* Fondo del modal (overlay) */
.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.185); /* Fondo oscuro */
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: opacity 0.3s ease-in-out;
  }
  
  .modal-overlay.open {
    display: flex;
    opacity: 1;
  }
  
  /* Contenedor del modal */
  .modal-container {
    position: fixed;
    top: 0;
    right: -100%; /* Inicialmente fuera de la pantalla */
    width: 50%;
    height: 100%;
    background-color: white;
    padding: 20px;
    transition: right 0.3s ease-in-out;
  }
  
  /* Cuando el modal está abierto, se desliza hacia la pantalla */
  .modal-container.open {
    right: 0;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    background: transparent;
    border: none;
    color: #333;
    cursor: pointer;
  }
  
  .close-btn:hover {
    color: #ff0000;
  }
  