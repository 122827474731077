.pdi-footer{
    background-color: #00234b;
    color: white;
    padding: 10px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.anchor-white{
    color:white;
    text-decoration: none;
}

.anchor-white:hover{
    color: #fed102 !important;
    text-decoration: none;
}

.pdi-footer-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
