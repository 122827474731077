/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.card-footer-home{
  display: flex;
  justify-content: space-between;
}

.fotoFondo{
  max-width: 90%;
}

p{
  font-size: 20px;
}

.opcion-btn{
  display: block;
  padding: 5px;
  width: 35px !important;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: gray;
  color: white;
  margin: 2px;
}

.opcion-container{
  display: flex;
  flex-wrap: wrap;
}

.hoverable:hover{
  cursor: pointer;
  background-color: gray;
  border-radius: 15px;
  padding: 5px;
  color: white;
  font-weight: 600;
}

.respondida {
  background-color: green;
}

.pregunta-activa{
  background-color: rgb(16, 16, 116);
  transform: scale(1.2);
  margin-left: 3px;
  margin-right: 3px;
}

.card-pregunta-modal{
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #495057cf;
  z-index: 9999;
}

.pdfviewer{
  width: 80vw;
  height: 80vh;
}

.botonera-tabla{
  display: flex;
  gap: 5px;
}

.custom-toggler .navbar-toggler-icon { 
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); 
}

.nav-show{
  position: fixed;
    right: 0;
    top: 50px;
    z-index: 20;
    background: rgb(0,35,75);
    padding: 10px;
}
